import React, { useRef } from "react";
import { sanitySrcUrl } from "../../lib/libFunctions";
import Content from "../block-content/block-content";
import { useParallaxInView } from "../../lib/hooks";
// components
import { Section } from "../section/section.component";
import { Carousel } from "../carousel-plain/carousel-full-page";
import AnimatedCharacters from "../animated-characters/animated-text";
import {
  base,
  containers,
  imageStyles,
  positioning,
  colors,
  motion,
  animations,
  useScroll,
  useInView,
} from "../../styles/base/_allBase.styles";
const { CenterDiv, H3, StyleP } = base;
const { clipPathAnimation, fadeInOutAnimation, inViewOptions } = animations;
const { ImgCover, Img } = imageStyles;
import {
  ContainerOne,
  ContainerTwo,
  InnerSectionDiv,
  ImgContainerCarousel,
  OuterDiv,
  InnerDiv,
  Wrapper,
} from "./carousel-section.styles";

const SectionCarouselContainer = ({ children }) => {
  return (
    <Wrapper name="wrapper">
      <OuterDiv />
      <InnerDiv name="innerDiv">{children}</InnerDiv>
    </Wrapper>
  );
};

const InnerSectionCarouselContainer = ({ children, key, side, ...props }) => {
  return (
    <motion.div
      key={key}
      name={"first div"}
      style={{
        height: "100%",
        width: "100%",
        background: "inherit",
        position: "absolute",
      }}
    >
      <InnerSectionDiv side={side} {...props}>
        {children}
      </InnerSectionDiv>
    </motion.div>
  );
};

const ClipPathImage = ({ children, src, side, ...props }) => {
  return (
    <ContainerOne side={side} name="container one" {...clipPathAnimation}>
      <ImgContainerCarousel name="imgContainer" {...props}>
        <ImgCover style={{ cursor: "none" }} src={src} {...props} fill />
      </ImgContainerCarousel>
    </ContainerOne>
  );
};

const filtered_keys = (obj, filter) => {
  let key,
    keys = [];
  for (key in obj)
    if (filter.test(key)) {
      keys.push(key);
    }
  return keys;
};

const SectionCarousel = ({
  array,
  room,
  timeOut = 10,
  side = "left",
  ...props
}) => {
  const { y, ref, isInView } = useParallaxInView(40);

  const structuredRoom = (data) => {
    const array = [data.room.mainImage, ...data.room.otherImages];
    const formattedArray = array.map((item) => {
      return {
        room: {
          header: data.room.header,
          text: data.room.text,
          alt: item.alt,
          mainImage: item,
          parentSlug: data.room.parentSlug,
          slug: data.room.slug,
        },
      };
    });
    return formattedArray;
  };

  const structuredArray = array ? array : structuredRoom(room);

  return (
    <Section size={"small"} nocursor={true}>
      <SectionCarouselContainer>
        <Carousel
          inherit={true.toString()}
          timeOut={timeOut}
          linkbutton={true.toString()}
          side={side}
        >
          {structuredArray.map((item, i) => {
            const key = filtered_keys(item, /\b[a-z]*[^_]/g);
            return (
              <InnerSectionCarouselContainer
                key={item._key}
                side={side}
                button={{
                  label: "Discover",
                  slug: `${item[key].parentSlug}/${item[key].slug.current}`,
                }}
                {...props}
              >
                <ClipPathImage
                  side={side}
                  alt={item[key].mainImage.alt}
                  src={sanitySrcUrl(item[key].mainImage)}
                  fill
                />
                <ContainerTwo side={side} name="container two">
                  <CenterDiv {...fadeInOutAnimation} ref={ref} style={{ y }}>
                    <AnimatedCharacters>
                      <H3>{item[key].header}</H3>
                    </AnimatedCharacters>
                    <StyleP {...fadeInOutAnimation}>
                      <Content blocks={item[key].text} />
                    </StyleP>
                  </CenterDiv>
                </ContainerTwo>
              </InnerSectionCarouselContainer>
            );
          })}
        </Carousel>
      </SectionCarouselContainer>
    </Section>
  );
};

export {
  SectionCarouselContainer,
  InnerSectionCarouselContainer,
  ClipPathImage,
  SectionCarousel,
};
