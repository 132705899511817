import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';
// colours
import * as colors from '../../styles/abstracts/_variables.styles';
import * as breakpoints from '../../styles/abstracts/_breakpoints';
// Breakpoints
const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } = breakpoints;
const buttonStyles = css`
	background-color: ${colors.warmPalette.contrast};
	color: ${colors.neutralPalette.white};
	opacity: 1;
	border: none;
	position: relative;
`;

const invertedButtonStyles = css`
	background-color: ${colors.warmPalette.neutralLight};
	color: ${colors.warmPalette.contrast};
	border: 0.2rem solid ${colors.warmPalette.secondary};
	animation: 0.8s ease-out;
`;

const googleSignInStyles = css`
	background-color: white;
	color: black;
`;

const getButtonStyles = (props) => {
	if (props.isGoogleSignIn) {
		return googleSignInStyles;
	}

	return props.inverted ? invertedButtonStyles : buttonStyles;
};

export const CustomButtonContainer = styled(motion.button)`
	font-family: 'Editorial New', sans-serif;
	width: auto;
	min-width: 18rem;
	height: 5rem;
	letter-spacing: 0.3rem;
	line-height: 0.95rem;
	padding: 1rem 1rem 1rem 1rem;
	font-size: 2rem;

	font-weight: 300;
	cursor: pointer;
	border-radius: 0rem;
	margin: 0.25rem 0rem;
	justify-content: center;
	justify-self: center;
	${getButtonStyles}
	display: inline-block;
	z-index: 10;
	min-width: 10rem;
	@media ${xxSmallDevices} {
		padding: 0.5rem 1.5rem 0.5rem 1.5rem;
		min-width: 10rem;
		height: 4rem;
	}
	@media ${extraSmallDevices} {
		min-width: 12rem;
		height: 4rem;
		font-size: 1rem;
		padding: 0.5rem 0.75rem 0.5rem 0.75rem;
	}
	@media ${mediumDevices} {
		${getButtonStyles}
		min-width: 20rem;
		height: 5rem;
	}
	@media ${mediumDevicesLandscape} {
		${getButtonStyles}
	}
	@media ${largeDevices} {
		${getButtonStyles}
		min-width: 20rem;
		height: 5rem;
	}
	@media ${extraLargeDevices} {
		${getButtonStyles}
	}
`;

export const CustomButtonTree = styled(motion.button)`
	font-family: 'Editorial New', sans-serif;
	width: auto;

	height: 6rem;
	letter-spacing: 0.3rem;
	line-height: 0.95rem;
	padding: 1rem 1rem 1rem 1rem;
	font-size: 2rem;
	font-weight: 300;
	cursor: pointer;
	border-radius: 0rem;
	margin: 0.25rem 0rem;
	justify-content: center;
	justify-self: center;
	${getButtonStyles}
	display: inline-block;
	z-index: 10;
	min-width: 80vw;
	@media ${xxSmallDevices} {
		padding: 0.5rem 1.5rem 0.5rem 1.5rem;

		height: 6rem;
	}
	@media ${extraSmallDevices} {
		height: 6rem;

		padding: 0.5rem 0.75rem 0.5rem 0.75rem;
	}
	@media ${mediumDevices} {
		${getButtonStyles}
	}
	@media ${mediumDevicesLandscape} {
		${getButtonStyles}
	}
	@media ${largeDevices} {
		${getButtonStyles}
	}
	@media ${extraLargeDevices} {
		${getButtonStyles}
	}
`;
