import {
  styled,
  motion,
  Img,
  colors,
  breakpoints,
  typo,
} from "../../styles/base/_allBase.styles";

const {
  xxSmallDevices,
  xxSmallDevicesLandscape,
  extraSmallDevices,
  extraSmallDevicesLandscape,
  mediumDevices,
  mediumDevicesLandscape,
  largeDevices,
  extraLargeDevices,
  xxLargeDevices,
} = breakpoints;

export const InnerSectionDiv = styled(motion.div)`
  background: ${colors.warmPalette.contrast};
  height: 60vh;
  width: 100%;
  margin: 10vh 0rem;
  z-index: 2;
  display: grid;
  grid-template-rows: 50% 50%;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    margin: 15vh 0rem;
  }
  @media ${mediumDevicesLandscape} {
    margin: 15vh 0rem;
  }
  @media ${largeDevices} {
    margin: 15vh 0rem;
  }
  @media ${extraLargeDevices} {
    grid-template-columns: ${({ side }) =>
      side === "left"
        ? "1.25fr 1fr"
        : side === "right"
          ? "1fr 1.25fr"
          : "1.25fr 1fr"};
    grid-template-rows: 1fr;
    grid-template-areas: "a b";
    margin: 15vh 0rem;
  }
  @media ${xxLargeDevices} {
    margin: 15vh 0rem;
  }
`;

export const ImgContainerCarousel = styled(motion.div)`
  position: relative;
  width: 80%;
  height: 140%;
  overflow: hidden;
  padding: 0rem;
  z-index: 2;
  margin: -10vh 10vw -10vh 10vw;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
    width: 88%;
    height: 80vh;
    margin: ${({ side }) =>
      side === "left"
        ? "-10vh 0vh -10vh 5vh"
        : side === "right"
          ? "-10vh 0vh -10vh 0vh"
          : "-10vh 0vh -10vh 5vh"};
  }
  @media ${xxLargeDevices} {
  }
`;

export const OuterDiv = styled(motion.div)`
  background: ${colors.warmPalette.contrast};
  height: 60vh;
  width: 100%;
  margin: 15vh 0rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const InnerDiv = styled(motion.div)`
  position: absolute;
  top: -15vh;
  left: 0;
  height: 100%;
  width: 100%;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
    height: 150%;
  }
`;

export const ContainerOne = styled(motion.div)`
  height: 100%;
  width: 100%;
  z-index: 2;

  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
    grid-area: ${({ side }) => (side === "left" ? "a" : "b")};
  }
  @media ${xxLargeDevices} {
  }
`;
export const ContainerTwo = styled(motion.div)`
  z-index: 2;
  height: 100%;
  width: 100%;
  padding: 3rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
    padding: 4rem;
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    padding: 0rem 8rem;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    padding: 0rem 10rem;
  }
  @media ${extraLargeDevices} {
    height: 100%;
    width: 100%;
    padding: 3rem;
    grid-area: ${({ side }) => (side === "left" ? "b" : "a")};
    margin-left: ${({ side }) => (side === "left" ? "0" : "4rem")};
  }
  @media ${xxLargeDevices} {
  }
`;

export const Wrapper = styled(motion.div)`
  position: relative;
  //   height: 60vh;
  width: 100%;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;
