import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Cursor,
  ButtonDiv,
  LinkContainer,
  StyledLeftArrow,
} from "./carousel.full-page.styles";
import {
  useCursorPosition,
  useCursorChange,
  useWrap,
  useCarousel,
} from "../../lib/hooks";
import { Children } from "react";
import {
  styled,
  AnimatePresence,
  motion,
  Img,
  colors,
  breakpoints,
  typo,
  useCycle,
} from "../../styles/base/_allBase.styles";
// Components
import { CustomButton } from "../button/button.component";
import LeftArrow from "../../assets/svg/left-arrow.svg";
// Animations
import { cursorDirectionAnimation } from "../../styles/base/_animations.styles";
// Assets
// import LeftArrow from "../../assets/svg/left-arrow.svg";

const Carousel = ({ children, timeOut = 0, side, ...props }) => {
  const { cursorYSpring, cursorXSpring } = useCursorPosition();
  const [size, setSize, visible, setVisible, rotate, rotation] =
    useCursorChange();
  const [imageIndex, showHide, changeImage] = useCarousel(children, 0);
  const childArray = React.Children.toArray(children);

  const timeoutRef = useRef(null);
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  useEffect(() => {
    resetTimeout();
    if (childArray[0].props.cycle) {
      timeoutRef.current = setTimeout(() => changeImage(1), 10000);
    } else {
      return;
    }
    return () => {
      resetTimeout();
    };
  }, [imageIndex]);

  return (
    <Container
      name={"carousel container"}
      onMouseEnter={setVisible}
      onMouseLeave={setVisible}
    >
      <AnimatePresence initial={false} mode="wait">
        {showHide && (
          <motion.div key={imageIndex}>
            {React.cloneElement(childArray[imageIndex], { ...props })}
          </motion.div>
        )}
      </AnimatePresence>
      <Cursor
        visible={visible.toString()}
        animate={
          rotate && visible ? "rotate" : !size && visible ? "small" : "exit"
        }
        variants={cursorDirectionAnimation}
        style={{
          translateX: cursorXSpring,
          translateY: cursorYSpring,
        }}
      >
        <StyledLeftArrow viewBox="0 0 74 74">
          <path
            d="M76.89962,74.61675l-23.31585-11.771L16.66254,44.20605,8.11891,39.8928v5L31.41868,33.21941l37.0749-18.57485,8.42688-4.22194a10.88288,10.88288,0,0,0,.025-2.5,12.00257,12.00257,0,0,1-.025-2.5L53.6207,17.096,16.54579,35.67086,8.11891,39.8928a25.67041,25.67041,0,0,0,0,5l23.31585,11.771L68.356,75.30349l8.54363,4.31326a15.30352,15.30352,0,0,1,.025-2.5c0-.27253.20362-2.38459-.025-2.5Z"
            transform="translate(-7.99664 -5.42262)"
          />
        </StyledLeftArrow>
      </Cursor>
      <ButtonDiv
        left={true.toString()}
        onClick={() => changeImage("prev")}
      ></ButtonDiv>
      <ButtonDiv
        onMouseEnter={rotation}
        onMouseLeave={rotation}
        right={true.toString()}
        onClick={() => changeImage("next")}
      ></ButtonDiv>
      {props.linkbutton && showHide && (
        <LinkContainer side={side}>
          <CustomButton
            onMouseEnter={setVisible}
            onMouseLeave={setVisible}
            inverted="true"
            href={childArray[imageIndex].props.button.slug}
          >
            {childArray[imageIndex].props.button.label}
          </CustomButton>
        </LinkContainer>
      )}
    </Container>
  );
};

export { Carousel };
