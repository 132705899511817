import LeftArrow from "../../assets/svg/left-arrow.svg";
import {
  styled,
  motion,
  Img,
  colors,
  breakpoints,
  typo,
} from "../../styles/base/_allBase.styles";

// Breakpoints
const {
  xxSmallDevices,
  xxSmallDevicesLandscape,
  extraSmallDevices,
  extraSmallDevicesLandscape,
  mediumDevices,
  mediumDevicesLandscape,
  largeDevices,
  extraLargeDevices,
  xxLargeDevices,
} = breakpoints;

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${
    "" /* background: ${(props) => (props.inherit === true ? 'inherit' : props.theme.colors.contrast)}; */
  }
  cursor: none;
  opacity: 1;
  z-index: 0;
`;

export const HalfScreenButton = styled.div`
  width: 100%;
  height: 100%;
  border: 1rem solid green;
  opacity: 0.5;
`;

export const Cursor = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 5rem;
  height: 5rem;
  border-radius: 7rem;
  background-color: ${(props) => props.theme.colors.warm.secondary};
  border: 1.25rem solid ${(props) => props.theme.colors.warm.secondary};
  mix-blend-mode: difference;
  z-index: 999;
  pointer-events: none;
  // Apply styles directly to any <svg> inside the Cursor
  svg {
    width: 100%;
    height: 100%;
  }
`;

// // Wrap the SVG in a styled-component
export const StyledLeftArrow = styled.svg`
  width: 100%;
  height: 100%;
`;

export const ButtonDiv = styled(motion.div)`
  height: 100%;
  width: 50%;
  left: ${(props) => (props.right ? "50%" : "0%")};
  position: absolute;
  z-index: 100;
  opacity: 1;
  object-fit: cover;
`;

export const LinkContainer = styled(motion.div)`
  position: absolute;
  cursor: pointer;
  z-index: 100000;
  @media ${xxSmallDevices} {
    top: 58%;
    left: calc(50% - 6rem);
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
    top: 58%;
    left: calc(50% - 6rem);
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    top: 75%;
    left: calc(50% - 10rem);
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    top: 75%;
    left: calc(50% - 10rem);
  }
  @media ${extraLargeDevices} {
    top: 70%;
    left: ${({ side }) =>
      side === "left" ? "80%" : side === "right" ? "7rem" : "50%"};
  }
  @media ${xxLargeDevices} {
  }
`;
